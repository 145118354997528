import { RESOURCE_UPDATE } from '../actions/resources'

type ActionTypes = typeof RESOURCE_UPDATE

export type Action = {
  path: string
  resource: Record<string, unknown>
  type: ActionTypes
}

function updateResource(state, { path, resource }) {
  const currentResource = state[path]
  return { ...state, [path]: { ...currentResource, ...resource } }
}

export default function resources(
  state: Record<string, unknown> = {},
  action: Action,
) {
  switch (action.type) {
    case RESOURCE_UPDATE:
      return updateResource(state, action)
  }

  return state
}
